import './carousel.css';
import React from "react";
import 'bootstrap/dist/js/bootstrap.js'

class Header1 extends React.Component {
    render(){
        return(
    <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src='main_bg.jpg' class="bd-placeholder-img"></img>

          <div class="container">
            
                <div class="carousel-caption">
                  <div class="container">
                    <div class="row header1">
                      <div class="col-sm  d-none d-lg-block">
                        <img src="st-main-opt.jpg" class="imageheaderlg hidden-xs" alt="Bootstrap Themes" loading="lazy"/>
                      </div>
                      <div class="col-sm">
                        <img src="logo-1.png" class="imageheader" alt="Bootstrap Themes" loading="lazy"/>
                        <p>Start your journey as a young Junior Chef and work your way up to a Master Chef by completing match 3 puzzles and earning stars.</p>
                        <p>
                        <div class="d-sm-none">
                        <a class="btn btn-sm btn-danger md-6 m-2" href="https://store.steampowered.com/app/855760/Sweet_Treats/">Steam Download</a>
                        <a class="btn btn-sm btn-danger md-6 m-2" href="https://www.youtube.com/watch?v=x0MxtMSrEGM">Watch the trailer</a>
                        </div>
                        <div class="d-none d-sm-block">
                        <a class="btn btn-lg-block btn-danger md-6 m-2" href="https://store.steampowered.com/app/855760/Sweet_Treats/">Steam Download</a>
                        <a class="btn btn-lg-block btn-danger md-6 m-2" href="https://www.youtube.com/watch?v=x0MxtMSrEGM">Watch the trailer</a>
                        </div>
                        </p>
                      </div>
                    </div>
                  </div>


                  
            </div>
          </div>
        </div>

        <div class="carousel-item">
          <img src='main_bg2.jpg' class="bd-placeholder-img"></img>

          <div class="container">
            
                <div class="carousel-caption">
                  <div class="container">
                    <div class="row header1">
                      <div class="col-sm  d-none d-lg-block">
                        <img src="at-screenshot.jpg" class="imageheaderlg hidden-xs" alt="Bootstrap Themes" loading="lazy"/>
                      </div>
                      <div class="col-sm">
                        <img src="AT-Logo.png" class="imageheader" alt="Bootstrap Themes" loading="lazy"/>
                        <p>Explore tons of scenery elements specially designed to enhance your experience through 100 levels of challenging game play puzzles.</p>
                        <p>
                        <div class="d-sm-none">
                        <a class="btn btn-sm btn-danger md-6 m-2" href="https://store.steampowered.com/app/1203910/Arabian_Treasures_Midnight_Match/">Steam Download</a>
                        <a class="btn btn-sm btn-danger md-6 m-2" href="https://www.youtube.com/watch?v=Z1AHSii9U88">Watch the trailer</a>
                        </div>
                        <div class="d-none d-sm-block">
                        <a class="btn btn-lg-block btn-danger md-6 m-2" href="https://store.steampowered.com/app/1203910/Arabian_Treasures_Midnight_Match/">Steam Download</a>
                        <a class="btn btn-lg-block btn-danger md-6 m-2" href="https://www.youtube.com/watch?v=Z1AHSii9U88">Watch the trailer</a>
                        </div>
                        </p>
                      </div>
                    </div>
                  </div>


                  
            </div>
          </div>
        </div>


      </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
    );
}
}

export default Header1;