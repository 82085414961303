import React from "react";
import './index.css'

class NavBar extends React.Component{
    render() {
        return(
<nav class="navbar stuck navbar-expand-lg fixed-top navbar-dark navbartop p-2">
    <a class="navbar-brand" href="">
        <img src="WhiteLogo1-300x701.png" class="toplogo" height="25"></img>
    </a>
     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end" id="navbar">
    <ul class="navbar-nav ml-auto toplinks">
        <li class="nav-item">
            <a class="nav-link" href="/">Home</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="http://press.redzonestudios.com">Press</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="/Privacy">Privacy Policy</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="/ContactUs">Contact</a>
        </li>
    </ul>
    </div>
</nav>
        );
    }
}

export default NavBar;