import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import Header1 from '../Header1.js'
import {data} from '../data'




export default class Home extends React.Component {
   state = { 
    isOpen: false, 
    selected: 0,
    version: 0 };

 handleShowDialog(id, version) {
    // this.setState({ 
    //  isOpen: !this.state.isOpen,
    //  selected: id });
    this.setState({ isOpen: true });
    this.setState({ selected: id });
    this.setState({ version: version });
    console.log('cliked');
 };

 handleHideDialog() {
  this.setState({ isOpen: false });
  console.log('cliked');
};


render() {
  return (<div className="Home">
        <Header1></Header1>
      
        <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
    <div class="col-md-8 p-lg-5 mx-auto">
      <h1 class="display-6 fw-normal">Games should be fun, unique and make you smile.</h1>
      <p class="lead fw-normal">- Jeff King (Owner RedZone Studios LLC).</p>
    </div>
    <div class="product-device shadow-sm d-none d-md-block"></div>
    <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
  </div>

  
  <section class="bg-light">
    <div class="container">
      <h2 >Games</h2>
      <p class="lead text-muted mb-4"></p>
      <div class="row">
         
      {
          data.map(data => (
            <div class="col-md-4 mb-4">
          <div class="card h-100">
            <div class="card-front shadow border-0 h-100">
              <div class="card-body">
              <img src={data.image} alt="" class={data.class}/>
              <div class="row d-block">

                <img src={data.imageback} alt="" class="card-img-ss mt-2" onClick={() => this.handleShowDialog(data.id, 0)}/>
                 

                <img src={data.imageback2} alt="" class="card-img-ss mt-2" onClick={() => this.handleShowDialog(data.id, 1)}/>
              </div>
                <h5 class="mt-3">{data.title}</h5>
                <p class="text-muted card-text">{data.content}</p>
                <a href={data.download} class="btn btn-outline-secondary m-2">Download</a>
                <a href={data.trailer} class="btn btn-outline-secondary m-2">Trailer</a>
              </div>
            </div>

            {this.state.isOpen && this.state.selected == data.id && (
                  <div onClick={() => this.handleHideDialog()} style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    cursor: 'pointer',
                    zIndex: 1000
                  }}>

                    {this.state.version == 0 && (
                    <img src={data.imageback}
                      alt=''
                      style={{
                        height: '70vw',
                        width: 'auto',
                        maxHeight: '700px',
                        paddingTop: '100px'
                      }} />
                    )}
                    {this.state.version == 1 && (
                    <img src={data.imageback2}
                      alt=''
                      style={{
                        height: '70vw',
                        width: 'auto',
                        maxHeight: '700px',
                        paddingTop: '100px'
                      }} />
                    )}

                  </div>
                )}
            {/* <div class="card-back bg-white">
                <div class="card-body">
                  <img src={data.imageback} alt="" class="card-img-top"/>
                  <p class="card-text mt-3">{data.back}</p>
                  <a href={data.download} class="btn btn-outline-secondary m-2">Download</a>
                  <a href={data.trailer} class="btn btn-outline-secondary m-2">Trailer</a>
                </div>
              </div> */}
          </div>
        </div>
          ))
        }

      </div>
    </div>
  </section>



  <div class="py-5 service-14">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
								<span class="badge badge-info rounded-pill px-3 py-1 font-weight-light">Software</span>
                <h3 class="my-3">We build great software in addition to games!</h3>
                <p>Game development is all about attention to detail and software can benefit from the same ideas.</p>
                <p>Are you interested in working with us?  Get in touch!</p>
								<a class="btn btn-info-gradiant border-0 text-white btn-md my-3" href="mailto:jeff@redzonestudios.com">Email Us</a>
            </div>
            <div class="col-lg-4">
                <div class="mb-4 mb-sm-0">
                    <img class="rounded img-fluid imgsub" src="qbs.jpg" alt="wrappixel kit" />
                    <div class="mt-3">
                        <h6 class="font-weight-medium">Quizbowl Systems, Inc.</h6>
                        <p class="mt-3">PC & web based classroom-based academic competition system.</p>
                        <a href="http://www.quizbowlsystems.com" class="linking">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="mb-4 mb-sm-0">
                    <img class="rounded img-fluid imgsub" src="omt.png" alt="wrappixel kit" />
                    <div class="mt-3">
                        <h6 class="font-weight-medium">Open Meeting Technologies</h6>
                        <p class="mt-3">Electronic voting and legislative meeting software.</p>
                        <a href="http://www.openmeetingtechnologies.com" class="linking">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container">
  <footer class="py-3 my-4">
    <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Home</a></li>
      <li class="nav-item"><a href="http://press.redzonestudios.com" class="nav-link px-2 text-muted">Press</a></li>
      <li class="nav-item"><a href="/Privacy" class="nav-link px-2 text-muted">Privacy Policy</a></li>
      <li class="nav-item"><a href="/ContactUs" class="nav-link px-2 text-muted">Contact</a></li>
    </ul>
    <p class="text-center text-muted">&copy; 2022 RedZone Studios LLC</p>
  </footer>
</div>
    </div>
  )}

}

//export default Home;
