import '../App.css';
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import Header1 from '../Header1.js'
import {data} from '../data'
import React from 'react';
import ReactDOM from 'react-dom/client';
import ContactForm from '../ContactForm'

function Privacy() {
  return (
    <div className="Privacy">
        
  <div class="position-relative overflow-hidden p-10 p-md-4 m-md-1 text-center">
    <div class="product-device shadow-sm d-none d-md-block"></div>
    <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
  </div>





            <header class="py-5">
                <div class="container px-5">
                    <div class="row gx-5 align-items-left justify-content-left">
         <p></p> 

<p></p><b>Privacy Policy for RedZone Studios LLC Games/Apps</b>

<p></p>At RedZone Studios LLC, we respect your privacy and are committed to protecting your personal information. This Privacy Policy describes how we collect, use, and share information when you use our games or apps.

<p></p><b>What Information Do We Collect?</b>

<p></p>We collect device information such as the device ID, system information, and analytics data to better understand how users interact with our games or apps. We do not collect any personal information, such as your name or email address.

<p></p><b>How Do We Use Your Information?</b>

<p></p>We use the information we collect to improve our games or apps and to provide you with a better experience. This includes analyzing user behavior and preferences, optimizing our games or apps, and providing more relevant content and advertising.

<p></p><b>Do We Share Your Information?</b>

<p></p>We may share the information we collect with third-party ad service providers for the purpose of showing you more relevant ads. We only share this information if you opt-in to personalized advertising on your device.

<p></p><b>How Do We Protect Your Information?</b>

<p></p>We take reasonable and appropriate measures to protect your information from unauthorized access, disclosure, or use. We use industry-standard security measures to protect your information during transmission and storage.

<p></p><b>Do We Collect Information From Children?</b>

<p></p>We do not knowingly collect any personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately.

<p></p><b>Your Rights</b>

<p></p>You do not have any rights to the information we collect. You may request that we restrict the processing of your information or object to our use of your information. If you would like to exercise any of these rights, please contact us using the information provided below.

<p></p><b>Changes to Our Privacy Policy</b>

<p></p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We recommend that you review this Privacy Policy periodically for any changes.

<p></p><b>Request Delete User Data</b>

<p></p>At any time you may request we purge and delete all your user data, please email support@redzonestudios.com with your request and we will remove any data we have collected from your device.

<p></p><b>Contact Us</b>

<p></p>If you have any questions about this Privacy Policy, please contact us at support@redzonestudios.com.

                    </div>
                </div>
            </header>




<div class="container">
  <footer class="py-3 my-4">
    <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      <li class="nav-item"><a href="/" class="nav-link px-2 text-muted">Home</a></li>
      <li class="nav-item"><a href="http://press.redzonestudios.com" class="nav-link px-2 text-muted">Press</a></li>
      <li class="nav-item"><a href="/Privacy" class="nav-link px-2 text-muted">Privacy Policy</a></li>
      <li class="nav-item"><a href="/ContactUs" class="nav-link px-2 text-muted">Contact</a></li>
    </ul>
    <p class="text-center text-muted">&copy; 2022 RedZone Studios LLC</p>
  </footer>
</div>
    </div>
  );
}

export default Privacy;
