import React from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import Privacy from './pages/Privacy';

const Main = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' element={<Home/>}></Route>
      <Route exact path='/contactus' element={<ContactUs/>}></Route> 
      <Route exact path='/privacy' element={<Privacy/>}></Route> 
      <Route exact path="/press" render={() => {window.location.href="/press/index.html"}} />
    </Routes >
  );
}

export default Main;