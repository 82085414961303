export const data = [
    {
      id: 1,
      title: "Sweet Treats",
      content: "Enjoy this wonderful pastry themed match 3 adventure! Start your journey as a young Junior Chef and work your way up to a Master Chef.",
      back: "Play through 100 levels of various challenging match 3 levels. Build your shop up with a wide variety of tasty treats that show off your skills as a pastry chef.",
      image: "logo-1.png",
      imageback: "st-main-opt.jpg",
      imageback2: "st-main-opt2.jpg",
      class: "card-img-top pink",
      download: "https://store.steampowered.com/app/855760/Sweet_Treats",
      trailer: "https://www.youtube.com/watch?v=x0MxtMSrEGM"
    },
    {
      id: 2,
      title: "Tabletop Basketball VR",
      content: "Tabletop Basketball VR brings a basketball experience that you're use to from the TV down on to a virtual table.",
      back: "The games are shorter than a normal basketball game to play a more action-oriented fast pace game that's a good fit for VR.",
      image: "tt-logo.gif",
      imageback: "tt-screenshot.jpg",
      imageback2: "tt-screenshot2.jpg",
      class: "card-img-top white",
      download: "https://store.steampowered.com/app/931210/Tabletop_Basketball_VR",
      trailer: "https://www.youtube.com/watch?v=PGlc-9xX07Y"
    },
    {
      id: 3,
      title: "Medieval Mystery Match",
      content: "Enjoy this medieval themed fantasy match 3 game. Play in a variety of environments and travel this fantasy world as you match your way through 100 levels.",
      back: " With more than 20 hours of gameplay content including a timed and relaxed mode, you'll be certain to enjoy this game for a long time.",
      image: "mmm-logo.png",
      imageback: "mmm-screenshot.png",
      imageback2: "mmm-screenshot2.png",
      class: "card-img-top green",
      download: "https://store.steampowered.com/app/659180/Medieval_Mystery_Match",
      trailer: "https://www.youtube.com/watch?v=WWWU5WuldTI"
    },
    {
      id: 4,
      title: "Battle Royale Warzone",
      content: "Battle Royale Warzone is a game designed for mobile for everyone to play! Jump into matches that only take about 4 minutes with up to 30 players per battle.",
      back: "Play on a huge map designed by hand for the most fun experience! The map has areas to hideout or hide in the maze.",
      image: "brw-logo.png",
      imageback: "brw-screenshot1.png",
      imageback2: "brw-screenshot2.png",
      class: "card-img-top yellow",
      download: "https://play.google.com/store/apps/details?id=com.redzonestudios.battleroyale",
      trailer: "https://www.youtube.com/watch?v=dZxK0C0N7Uc"
    },
    {
      id: 5,
      title: "Arabian Treasures: Midnight Match",
      content: "Explore this Arabian themed match 3 puzzle adventure! Begin this hand crafted world as a poor traveler who desires to seek treasures beyond your wildest expectations.",
      back: "Earn star rewards for your expert skills during the time limited mode or play a relaxed version of the game and take as much time as you need.",
      image: "AT-Logo.png",
      imageback: "at-screenshot.jpg",
      imageback2: "at-screenshot2.jpg",
      class: "card-img-top purple2",
      download: "https://store.steampowered.com/app/1203910/Arabian_Treasures_Midnight_Match",
      trailer: "https://youtu.be/Z1AHSii9U88"
    },
    {
      id: 6,
      title: "Unwalkable",
      content: "Unwalkable is a silly physics based open-ended puzzle platformer where you control a wobbly character.",
      back: "You control a physically based character. Control the limbs to grab, pull and push to interact with objects in the world.",
      image: "U-Logo.png",
      imageback: "u-screenshot.jpg",
      imageback2: "u-screenshot2.jpg",
      class: "card-img-top blue",
      download: "https://store.steampowered.com/app/1299950/Unwalkable",
      trailer: "https://www.youtube.com/watch?v=-OpH1-5K4sI"
    },
    ]

export default data;