import './App.css';
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import NavBar from './NavBar.js'
import Header1 from './Header1.js'
import {data} from './data'
import Main from './Main.js'

function App() {
  return (

    
    <div className="App">
        <NavBar></NavBar>
        <Main />
    </div>
  );
}

export default App;
