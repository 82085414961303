import '../App.css';
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import Header1 from '../Header1.js'
import {data} from '../data'
import React from 'react';
import ReactDOM from 'react-dom/client';
import ContactForm from '../ContactForm'

function ContactUs() {
  return (
    <div className="ContactUs">
        
  <div class="position-relative overflow-hidden p-10 p-md-4 m-md-1 text-center">
    <div class="product-device shadow-sm d-none d-md-block"></div>
    <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
  </div>





            <header class="bg-dark2 py-5">
                <div class="container px-5">
                    <div class="row gx-5 align-items-center justify-content-center">
                        <div class="col-lg-8 col-xl-7 col-xxl-6">
                            <div class="my-5 text-center text-xl-start">
                                <h1 class="display-5 fw-bolder text-white mb-2">Contact Us</h1>
                                <p class="lead fw-normal text-white-50 mb-4">We're located in the northwest suburbs of Illinois.  If you have any questions, please email me directly at jeff@redzonestudios.com.  Have fun, good luck!</p>
                                <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                    <a class="btn btn-primary btn-lg px-4 me-sm-3" href="mailto:jeff@redzonestudios.com">Send Email</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img class="img-fluid rounded-3 my-5" src="wishlist.jpg" alt="..." /></div>
                    </div>
                </div>
            </header>




<div class="container">
  <footer class="py-3 my-4">
    <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      <li class="nav-item"><a href="/" class="nav-link px-2 text-muted">Home</a></li>
      <li class="nav-item"><a href="http://press.redzonestudios.com" class="nav-link px-2 text-muted">Press</a></li>
      <li class="nav-item"><a href="/Privacy" class="nav-link px-2 text-muted">Privacy Policy</a></li>
      <li class="nav-item"><a href="/ContactUs" class="nav-link px-2 text-muted">Contact</a></li>
    </ul>
    <p class="text-center text-muted">&copy; 2022 RedZone Studios LLC</p>
  </footer>
</div>
    </div>
  );
}

export default ContactUs;
